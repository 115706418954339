<template>      
  <v-footer
    class="item-dialog__footer d-flex align-items-center"
    height="auto"
    min-height="52"
    :dark="dark"
    :color="type === 'edit' || type === 'create' || type === 'delete' ? 'inherit' : 'transparent'"
  >
    <!-- <template
      v-if="type === 'preview'"
    >
      <div
        style="width: 100%; height: 24px;"
        class="d-flex align-items-center"
      >
        <v-icon
          style="height: 20px;"
          size="20"
        >
          mdi-label-outline
        </v-icon> 
        <div
          class="ml-2 flex-grow-1"
          style="height: 20px;"
        >
          footer information
        </div>
      </div>
    </template> -->
    <template
      v-if="type === 'edit' || type === 'create' || type === 'delete'"
    >
      <v-spacer />
      <v-btn
        text
        @click="$emit('clickCancel')"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        :color="type !== 'delete' ? 'primary' : 'error'"
        :loading="loading"
        @click="$emit('clickSuccess')"
      >
        <template v-if="type === 'edit'">
          Save
        </template>
        <template v-else-if="type === 'delete'">
          Delete
        </template>
        <template v-else>
          Create
        </template>
      </v-btn>
    </template>
  </v-footer>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      required: true
    }
  },
  name: 'ItemDialogBottomToolbar'
}
</script>
<style lang="scss">
.item-dialog__footer {
  border-color: transparent !important;
    border-top: #dddddd !important;
    border-style: solid !important;
    border-width: 1px !important;
    border: 0px;
    background-color: transparent !important;
}
.theme--dark .item-dialog__footer {
  background: #000;
}
</style>